import { useRef, useEffect } from "react"

const useInput = () => {
  const inputRef = useRef(null)
  const element = inputRef?.current?.inputElement
  const defaultValue = element?.defaultValue

  const setInputRef = (reference) => {
    inputRef.current = reference
  }

  const updateMask = (element, value) =>
    element && element?.textMaskInputElement?.update(value)

  useEffect(() => {
    const input = inputRef?.current?.inputElement
    const active = document.activeElement

    if (input !== active) {
      if (defaultValue) {
        input.value = defaultValue
      }

      updateMask(inputRef?.current, input?.value)
    }
  }, [defaultValue])

  return { setInputRef }
}

export default useInput
